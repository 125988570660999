<template>
	<GridButton
		:tag-name="tagName"
		:href="href"
		:target="target"
		:type="type"
		:content="content"
		data-qa="builder-gridelement-button"
		@click.prevent
		@drag.prevent
		@dragstart.prevent
	/>
</template>

<script>
import GridButton from '@user/components/grid-components/button/GridButton.vue';
import { useGridButton } from '@user/components/grid-components/button/useGridButton';

export default {
	name: 'GridButtonProviderBuilder',
	components: { GridButton },
	props: {
		data: {
			type: Object,
			required: true,
		},
		isPreviewMode: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const {
			tagName,
			href,
			target,
			type,
			content,
		} = useGridButton(props);

		return {
			tagName,
			href,
			target,
			type,
			content,
		};
	},
};
</script>
